//import * as caseService from "./caseService";
import * as utils from "./utilsService";
import {
  ExportToCsv
} from "export-to-csv";
import * as caseService from "./caseService";
import * as lineItemService from "./lineItemSevice";
import Vue from "vue";
import {
  UserSummary
} from "../models/userSummary";
import moment from "moment";
//import moment from 'moment-timezone';
function getBatchRef(orgId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("batches")

}

function getBatchItemsRef(orgId, batchId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("batches")
    .doc(batchId)
    .collection("batchItems")

}

function getInvoiceRef(orgId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("invoices")
}

function getProgressNoteRef(orgId, caseId, goalId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases")
    .doc(caseId)
    .collection("goals")
    .doc(goalId)
    .collection("progressNotes");
}
export function getInvoiceRefId(orgId)
{
  return getInvoiceRef(orgId);
}
export function getSelectedLineItemDefinition() {
  const currentDefinition = window.localStorage.getItem('currentLineItemDefinition');
  //console.log(currentDefinition);
  const parsedDef = JSON.parse(currentDefinition);
  console.log(parsedDef);
  return parsedDef;
}
export async function setSelectedLineItemDefinition(definition) {
  //let lineItems = await getLineItems(definition.id);
 // console.log(lineItems);
  //definition.lineItems = lineItems;
  return window.localStorage.setItem('currentLineItemDefinition', JSON.stringify(definition));

}

export async function addInvoice(orgId ,invoiceId)
{
  const data={}
  return getInvoiceRef(orgId).doc(invoiceId).set(data);
  
}

export async function getLineItems(definitionId) {
  let lineItems = window.localStorage.getItem('currentLineItems');

  if (lineItems !== null) {
    lineItems = JSON.parse(lineItems);
  } else {
    console.log("hi");
    lineItems = [];
    await lineItemService.listAllLineItems(definitionId).then(async (lineItemsSnapshot) => {

      await lineItemsSnapshot.docs.map(async doc => {
        let lineItem = doc.data();
        lineItem.id = doc.id;
        lineItems.push(lineItem)
        console.log(lineItems);
      });
      window.localStorage.setItem('currentLineItems', JSON.stringify(lineItems));

    })
  }
  return lineItems;
}



export async function extractNDIS(items) {
  var data = [];
  
console.log(items);
  items.forEach(x => {
    data.push({
      // Key:x.key,
      RegistrationNumber: x.providerRegistrationNumber,
      NDISNumber: x.nDISNumber,
      SupportsDeliveredFrom: moment(Vue.prototype.$utils.toDt(x.startDate)).format("DD"+'/'+"MM"+'/'+"YYYY"),
      SupportsDeliveredTo: moment(Vue.prototype.$utils.toDt(x.endDate)).format("DD"+'/'+"MM"+'/'+"YYYY"),
      SupportNumber: x.supportItemNumber,
      ClaimReference: x.id,
      Quantity: (x.unitOfMeasure === "items" ? x.totalDuration : ""),
      Hours: (x.unitOfMeasure !== "items" ? utils.hoursToHoursMinFormat(x.totalDuration) : ""),

      UnitPrice: x.unitPrice,
      GSTCode: x.gSTCode,
      AuthorisedBy: "",
      ParticipantApproved: "",
      InKindFundingProgram: "",
      ClaimType: x.claimType,
      CancellationReason: x.cancellationReason,
      providerABN: x.abn

    });
  });

  

  const options = {
    fieldSeparator: ",",
    quoteStrings: false,
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    //title: "",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}

export async function extractNdis(data) {

 
  const dt = new Date;
  
  console.log(dt);
  //const utils=Vue.prototype.$utils;

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}
export async function extractGeneric(data) {
  console.log(data)
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}
export async function extractXero(data) {

 
  const dt = new Date;
  
  console.log(dt);
  //const utils=Vue.prototype.$utils;

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}

export async function getBatchById(orgId, id) {
  return getBatchRef(orgId)
    .doc(id)
    .get();
}

export async function listBatches(orgId) {
  return getBatchRef(orgId)
    .get();
}


export async function listBatchItems(orgId, batchId) {
  return getBatchRef(orgId)
    .doc(batchId)
    .collection("batchItems")
    .get();
}
export async function saveBatch(orgId, payload, user) {
  if (payload.createdBy == undefined) {
    payload.createdBy = Object.assign({}, new UserSummary(user));
    payload.createdOn = new Date();
  }

  payload.modifiedBy = Object.assign({}, new UserSummary(user));
  payload.modifiedOn = new Date();

  const obj = Object.assign({}, payload);
  if (obj.id != undefined) {
    return getBatchRef(orgId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getBatchRef(orgId).add(obj);
  }
}


export async function addToBatch(orgId, batchItem, progressNote) {
  const batch = Vue.prototype.$db.batch();

  const progressNoteRef = getProgressNoteRef(orgId, batchItem.participantId, progressNote.goalId).doc(progressNote.id);
  const batchItemRef = getBatchItemsRef(orgId, batchItem.batchId).doc(progressNote.id);

  if (utils.isEmpty(progressNote.assignedBatches)) {
    progressNote.assignedBatches = [];
  }
  if (progressNote.assignedBatches.indexOf(batchItem.batchId) < 0) {
    progressNote.assignedBatches.push(batchItem.batchId);
    batch.set(progressNoteRef, progressNote)
  }

  console.log(progressNoteRef);
  console.log(batchItem.invoiceNumber);

  batch.set(batchItemRef, Object.assign({}, batchItem))

  return batch.commit();

}



export async function removeFromBatch(orgId, payload) {

  return getBatchRef(orgId)
    .doc(payload.batchId)
    .collection("batchItems")
    .doc(payload.progressNoteId)
    .delete()
}


export async function extractInvoices(orgId, validItems) {

  var data = [];
  //const participants =[];
  const ids = [];
  validItems.forEach(item => {
    ids.push(item.caseId)
  });

  // await caseService.listByMultipleId(orgId,ids).then(results => {
  //     results.docs.map(doc => {
  //       let item = doc.data();
  //       item.id = doc.id;
  //       participants.push(item);
  //     });
  //   });



  for (let i = 0; i <= validItems.length; i++) {
    let x = validItems[i];
    let caseData = null;
    // let caseData= participants.find(p => {
    //     return p.id == x.caseId;
    // });

    await caseService.getById(orgId, x.caseId).then(doc => {
      caseData = doc.data();
    });
    let pd = null;

    pd = caseData.participantDetails
    let row = {
      LastName: utils.isEmpty(pd) ?
        "" :
        utils.emptyThen(pd.lastName, ""),
      FirstName: utils.isEmpty(pd) ?
        "" :
        utils.emptyThen(pd.firstName, ""),
      InvoiceNumber: utils.emptyThen(x.id, ""),
      CRNNumber: utils.isEmpty(pd) ?
        "" :
        utils.emptyThen(pd.cRNNumber, ""),
      NDISNumber: utils.isEmpty(pd) ?
        "" :
        utils.emptyThen(pd.nDISNumber, ""),
      SWSNumber: utils.isEmpty(pd) ?
        "" :
        utils.emptyThen(pd.sWSNumber, ""),
      TaxFileNumber: utils.isEmpty(pd) ?
        "" :
        utils.emptyThen(pd.taxFileNumber, ""),
      Description: utils.emptyThen(x.lineItem.supportItemNumber, "") +
        " - " +
        utils.emptyThen(x.lineItem.supportItem, ""),

      TotalAmount: utils.emptyThen(x.price, 0),
      ItemAmount: utils.emptyThen(x.price, 0) *
        utils.emptyThen(x.quantity == undefined ? x.duration : x.quantity, 1)
    }
    const obj = Object.assign(row, caseData.additionalData);

    data.push(obj);
  }


  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: false,
    //title: "",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}